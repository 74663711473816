import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import GalleryContent from "../components/Gallery/GalleryContent";

function Gallery() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Gallery">
      <div className="w-full">
        <TransparentHeader
          headertitle="Gallery"
          Subheader="Gallery"
          bgimg={`${rpdata?.gallery?.[66]}`}
        />

        <div>
          <GalleryContent galleryImages={rpdata?.gallery} />

        </div>
      </div>
    </BaseLayout>
  );
}

export default Gallery;