import { Helmet } from "react-helmet";
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Header from "./header/Header_2";
import Footer from "./footer/Footer_2";
import ScrollProgressBar from './ScrollProgressBar'
import ContentWidgetButton from "./botonWidgets/ContentWidgetButton";
import BackToTop from '../global/BackToTop'

function BaseLayout(props) {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <>
      <Helmet>
        <title>
          {props.PageName} | {`${rpdata?.dbPrincipal?.name}`}
        </title>
        <meta
          name="description"
          content={`${rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}`}
        />
        <meta
          name={` ${rpdata?.dbPrincipal?.name}`}
          content={` ${rpdata?.dbPrincipal?.name}`}
        ></meta>
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <link rel="icon" href={rpdata?.dbPrincipal?.favicon} />
      </Helmet>
      <Header />
      <ScrollProgressBar />

      <main>{props.children}</main>



      {/* widgets */}
      {
        rpdata?.simpleWidgets?.[3]?.activo ?
          null
          :
          <ContentWidgetButton />

      }

      <Footer />
      <BackToTop />

    </>
  );
}

export default BaseLayout;
