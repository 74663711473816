import React, { useContext, useState } from "react";
import { GlobalDataContext } from "../../../context/context";
import { FaWhatsapp, FaChartBar } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import Whatsapp from "./Whatsapp";
import VisorCounter from "./VisorCounter";

function WidgetButton() {
  const [active, setActive] = useState(false);
  const [widget, setWidget] = useState(true);
  const [visor, setVisor] = useState(false);

  const { rpdata } = useContext(GlobalDataContext);

  const handleButton = () => {
    setActive(false);
    setVisor(false);
    setWidget(!widget);
  };

  const handleVisor = () => {
    setActive(false);
    setVisor(!visor);
  };



  const handleWhatsapp = () => {
    setActive(!active);

    setVisor(false);
  };




  return (
    <div>
      <div className="container-floating-widget">
        {widget ? (
          <div className="fixed bottom-[150px] md:bottom-[95px] right-[40px] z-10 flex flex-col justify-between">
            {rpdata?.simpleWidgets?.map((item) => {
              if (item.val === "WhatsappBtn" && item.activo === true) {
                return (
                  <div className="relative">
                    <div
                      className="nd1 nds my-1"
                      onClick={() => handleWhatsapp()}
                    >
                      <FaWhatsapp className="item-widget text-white" />
                    </div>
                    {<Whatsapp stateChat={active} closeBtn={() => setActive(false)} />}
                  </div>
                );
              }
              if (item.val === "VisitsCounter" && item.activo === true) {
                return (
                  <div className="relative">
                    <div className="nd2 nds my-1" onClick={() => handleVisor()}>
                      <FaChartBar className="item-widget text-white" />
                    </div>
                    {visor ? <VisorCounter /> : null}
                  </div>
                );
              }

              return null;
            })}

          </div>
        ) : null}
        <div className="floating-button-widgets text-white">
          <BiCategoryAlt
            className="principal-btn text-white"
            onClick={() => handleButton()}
          />
        </div>
      </div>

    </div>
  );
}

export default WidgetButton;
